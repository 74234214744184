
  
  
  import FETCHING from '../../library/fetching'
  import UMUM from '../../library/umum'
  import DATAMASTER from '../../library/dataMaster'
  
  export default {
    data() {
      return {
  
        form : {
          id : '',
          no : '',
          uraian : '',
          unit_kerja : '',
          master_permintaan : 1,
          realisasi : 0,
          tgl : '',
          keterangan : '',
          dd : '',
          mm : '',
          yy : '',
          file : null,
          type : '',
        },

        rincian : {
          id : '',
          kegiatan_sub_label_id : '',
          kegiatan_sub_kode : '',
          uraian : '',
          koefisien : '',
          satuan : '',
          harga : 0,
          ppn : 0,
          jml : 0,
          file_old: "",
        },


        serapan : {
          id : '',
          rincian_id : '',
          realisasi_keuangan_id : '',
          realisasi : '',
          dd : '',
          mm : '',
          yy : ''
        },
        filterku : {
          program_kode : '',
          tahun : '',
          unit_kerja : '',
          instansi : '',
        },


        TOTAL_SERAPAN : 0,

  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
  
  
       
        list_data : [],
        list_data_serapan : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 8,
        cari_value: "",
        cek_load_data : true,
  
  
        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        mdl_view_lampiran : false,
        btn_add: false,



        mdl_view_list_serapan : false,
        mdl_select_list_serapan : false,
        mdl_edit_serapan : false,
        mdl_delete_serapan : false,


        mdl_list_rincian : false,
  
  
        FETCHING : FETCHING,
        UMUM : UMUM,
        DATAMASTER : DATAMASTER,
      }
    },
    methods: {
  
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                instansi : this.filterku.instansi,
                unit_kerja : this.filterku.unit_kerja,
                tahun : this.filterku.tahun,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                // console.log(res_data);
        });
      },
  
  
      addData : function() {

        this.form.dd = this.ReConvertFormatDate(this.form.tgl).dd
        this.form.mm = this.ReConvertFormatDate(this.form.tgl).mm
        this.form.yy = this.ReConvertFormatDate(this.form.tgl).yy

        var formData = new FormData();
        formData.append('uraian', this.form.uraian);
        formData.append('no', this.form.no);
        formData.append('master_permintaan', this.form.master_permintaan);
        formData.append('realisasi', this.form.realisasi);
        formData.append('unit_kerja', this.form.unit_kerja);
        formData.append('tgl', this.form.tgl);
        formData.append('keterangan', this.form.keterangan);
        formData.append('dd', this.form.dd);
        formData.append('mm', this.form.mm);
        formData.append('yy', this.form.yy);
        formData.append('file', this.form.file);

        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN + "addData", {
            method: "POST",
            headers: {
                authorization : 'kikensbatara '+localStorage.token
            },
            body: formData
        }).then(res => res.json()).then((data_urusan) => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },
  
  
      editData : function(){

        this.form.dd = this.ReConvertFormatDate(this.form.tgl).dd
        this.form.mm = this.ReConvertFormatDate(this.form.tgl).mm
        this.form.yy = this.ReConvertFormatDate(this.form.tgl).yy

        var formData = new FormData();
        formData.append('id', this.form.id);
        formData.append('uraian', this.form.uraian);
        formData.append('no', this.form.no);
        formData.append('master_permintaan', this.form.master_permintaan);
        formData.append('realisasi', this.form.realisasi);
        // formData.append('unit_kerja', this.form.unit_kerja);
        formData.append('tgl', this.form.tgl);
        formData.append('keterangan', this.form.keterangan);
        formData.append('dd', this.form.dd);
        formData.append('mm', this.form.mm);
        formData.append('yy', this.form.yy);
        formData.append('file', this.form.file);
        formData.append('file_old', this.form.file_old);

        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN + "editData", {
            method: "POST",
            headers: {
                authorization : 'kikensbatara '+localStorage.token
            },
            body: formData
        }).then(res => res.json()).then((res_data) => {
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(E){
        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },
  
      selectData : function(data){
        this.form.id = data.id ;
        this.form.no = data.no ;
        this.form.master_permintaan = data.master_permintaan ;
        this.form.uraian = data.uraian ;
        this.form.realisasi = data.realisasi ;
        // this.form.unit_kerja = data.unit_kerja ;
        this.form.tgl = data.yy +'-'+UMUM.addZeroDate(data.mm)+'-'+UMUM.addZeroDate(data.dd) ;
        this.form.keterangan = data.keterangan ;
        this.form.dd = data.dd ;
        this.form.mm = data.mm ;
        this.form.yy = data.yy ;
        this.form.type = data.type ;
        this.form.file_old = data.file;

        this.serapan.realisasi_keuangan_id = data.id


        this.viewSerapan();
      },

      selectRincian : function(data){
        this.rincian.id = data.id ;
        this.rincian.kegiatan_sub_label_id = data.kegiatan_sub_label_id ;
        this.rincian.kegiatan_sub_kode = data.kegiatan_sub_kode ;
        this.rincian.uraian = data.uraian ;
        this.rincian.koefisien = data.koefisien ;
        this.rincian.satuan = data.satuan ;
        this.rincian.harga = data.harga ;
        this.rincian.ppn = data.ppn ;
        this.rincian.jml = data.jml ;


        
      },

      viewSerapan : function(){
        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN_SERAPAN + "getOne", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                realisasi_keuangan_id: this.serapan.realisasi_keuangan_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data);
                this.list_data_serapan = res_data;
                this.TOTAL_SERAPAN = 0
                res_data.forEach(element => {
                  this.TOTAL_SERAPAN = this.TOTAL_SERAPAN + element.realisasi
                });
        });
      },

      editSerapan : function(){
        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN_SERAPAN + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.serapan)
        }).then(res_data => {
            this.Notify('Sukses mengubah Data', 'primary', 'check_circle_outline');
            this.viewSerapan();
        });
      },

      removeSerapan : function(){

        fetch(this.$store.state.url.CT_REALISASI_KEUANGAN_SERAPAN + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.serapan)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.viewSerapan();
        });

        
      },

      selectSerapan : function(data){
          this.serapan.id = data.id ;
          this.serapan.rincian_id = data.rincian_id ;
          this.serapan.realisasi_keuangan_id = data.realisasi_keuangan_id ;
          this.serapan.realisasi = data.realisasi ;
          this.serapan.dd = data.dd ;
          this.serapan.mm = data.mm ;
          this.serapan.yy = data.yy ;      
      },

      // ====================================== CONTOH eDOC ====================================
      onClickx(data){
        alert(data)
      },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {FETCHING.getContohAtocomplete(val)}
          })
      },
      ReConvertFormatDate : function(data){
        const d = new Date(data)
        return {
          yy : d.getFullYear(),
          mm : d.getMonth() + 1,
          dd : d.getDate(),
        }

      },
      async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        await this.onChangexUnitKerja();
      },
      async onChangexUnitKerja(){
        var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
        console.log(unitkerjax[0]);
        this.filterku.unit_kerja = unitkerjax[0].id
        console.log(this.filterku.unit_kerja);
        this.getView();
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },

            indexing : function(index){
                var idx = ((this.page_first-1)*this.page_limit)+index
                return idx
            },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {

      let profilex = JSON.parse(localStorage.profile);
      let profile = profilex.profile;
      this.form.unit_kerja = profile.unit_kerja;
      // this.kegiatanSub.unit_kerja = profile.unit_kerja;

      this.filterku.instansi = profile.instansi_id;
      this.filterku.unit_kerja = profile.unit_kerja;

      const d = new Date();
      let year = d.getFullYear();

      // this.form.tahun = year;
      this.filterku.tahun = year;



      FETCHING.getContohAtocomplete('');
      DATAMASTER.getMasterPermintaan();
      DATAMASTER.getTahun();
      // this.getView();
      this.onChangexInstansi(); 
    },
  }
  